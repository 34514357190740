import { Box, TablePagination, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PathList } from "../../../Common/Routes/Path";
import viewicon from "../../../Assets/image/user/223_frame.png";
import { apiList } from "../../../Common/Api/ApiList";
import AxiosInstance from "../../../Common/Api/ApiHelper";
import ErrorToaster from "../../../Component/ErrorToaster";
import Loader from "../../../Common/Loader";
import { DataGrid } from "@mui/x-data-grid";
import NoDataImg from "../../../Common/NoData";

function createData(id, username, email, type, date, img) {
  return { id, username, email, type, date, img };
}

const rows = [
  createData(
    998,
    "Carol Diaz",
    "fecund_foreman_23@gmail.com",
    "User",
    `19-Apr-2023/12:20 AM`,
    `${viewicon}`
  ),
  createData(
    998,
    "Kendrik Chizimu",
    "bellicose_unicorn_92@gmail.com",
    "User",
    "19-Apr-2023/12:20 AM",
    `${viewicon}`
  ),
  createData(
    998,
    "Samantha Wilson",
    "ximena.martínez@gmail.com",
    "Trainer",
    "19-Apr-2023/12:20 AM",
    `${viewicon}`
  ),
  createData(
    998,
    "Bence Korhonen",
    "mannered_48@gmail.com",
    "User",
    "19-Apr-2023/12:20 AM",
    `${viewicon}`
  ),
  createData(
    998,
    "Shakia Ababio",
    "petulant_nibblets_35@gmail.com",
    "Trainer",
    "19-Apr-2023/12:20 AM",
    `${viewicon}`
  ),
  createData(
    998,
    "Tawana Ababio",
    "sagacious_patker_36@gmail.com",
    "User",
    "19-Apr-2023/12:20 AM",
    `${viewicon}`
  ),
];

const PAGE_SIZE_OPTIONS = [29];
export default function Supportscreen() {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
  const [totalData, setTotalData] = useState(0);
  const [isPrevClick, setIsPrevClick] = useState(false);
  const [loading, setloading] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const columns = [
    {
      headerClassName: "super-app-theme--header",
      field: "id",
      headerName: "ID",
      flex: 1,
      minWidth: 60,
      headerAlign: "center",
    },

    {
      headerClassName: "super-app-theme--header",
      field: "username",
      headerName: "User Name",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "email",
      headerName: "Email",
      type: "number",
      flex: 1,
      minWidth: 230,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "type",
      headerName: "Type",
      type: "string",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "date",
      headerName: "Date",
      type: "string",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      headerName: "View",
      sortable: false,
      flex: 1,
      minWidth: 100,
      headerAlign: "center",

      renderCell: (e) => (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={viewicon}
            alt=""
            onClick={() => navigate(`${PathList.Chat}/${e.row.id}`)}
          />
        </Box>
      ),
    },
  ];

  const fetchAppIssueList = async (
    page = 0,
    pageSize = PAGE_SIZE_OPTIONS[0]
  ) => {
    setloading(true);
    try {
      let queryParams = new URLSearchParams({
        count: page === 0 ? page * pageSize : page * pageSize + 1,
      });
      const api = `${apiList.getAppIssueList}?${queryParams.toString()}`;
      const res = await AxiosInstance.get(api);
      if (res?.data?.r?.length > 0) {
        setList(res?.data?.r);
        setTotalData((prev) =>
          page === 0
            ? res?.data?.r?.length
            : isPrevClick
            ? prev - res?.data?.r?.length
            : prev + res?.data?.r?.length
        );
        setIsLoading(false);
      } else {
        console.log(res?.data?.m);
        setIsLoading(false);
        setList([]);
        setTotalData(0);
      }
      setloading(false);
    } catch (error) {
      setloading(false);
      setIsLoading(false);
      <ErrorToaster error={error} />;
    }
  };

  useEffect(() => {
    fetchAppIssueList(page, rowsPerPage);
  }, [page, rowsPerPage]);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    setIsPrevClick(page > newPage);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  return (
    <Box
      sx={{
        height: "calc(100vh - 100px)",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        borderRadius: "5px",
      }}
    >
      {isLoading ? (
        <Box
          sx={{
            height: "calc(100vh - 100px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
          }}
        >
          <Loader />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              height: "calc(100vh - 150px)",
            }}
          >
            <DataGrid
              rows={rows}
              rowCount={-1}
              pagination
              paginationMode="server"
              disableColumnMenu={true}
              // disableColumnSorting={true}
              disableAutosize={true}
              disableColumnFilter={true}
              disableRowSelectionOnClick={true}
              getRowId={(e) => e.id}
              hideFooter
              loading={loading}
              slots={{ noRowsOverlay: NoDataImg }}
              columns={columns}
              sx={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                ".MuiDataGrid-cell": { textAlign: "center" },
                ".MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "600",
                  color: "white",
                },
                "& .super-app-theme--header": {
                  background: theme.palette.primary.Blend,
                },
              }}
              pageSizeOptions={[10]}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
            />
            <TablePagination
              component="div"
              count={totalData}
              page={page}
              onPageChange={handlePageChange}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleRowsPerPageChange}
              rowsPerPageOptions={PAGE_SIZE_OPTIONS}
            />
          </Box>
        </>
      )}
    </Box>
  );
}
