export const apiList = {
  // AUTH

  LOGIN: "/auth/admin-login",
  ForgotPassword: "/auth/forgot-password",
  //Dash
  Dashboard: "/admin/dashboard",
  //Booking
  GetBookingList: "/admin/booking-list",
  GetBookingDetailsById: "/admin/booking-detail",
  //user
  UserAction: "/admin/user-action",
  UserApproval: "/admin/user-approval",
  UserList: "/admin/user-list",
  UserDetailsById: "/admin/user-details",
  DeleteUser : "/admin/user-action",
  TrainersList: "/admin/trainer-list",
  TrainersDetails: "/admin/trainer-details",
  TrainersDetails_slots: "admin/trainer-details/slots",
  TrainersDetails1: "/admin/trainer-details_1",
  TrainersDetails2:"/admin/get-review-order-list",
  SendNotifications: "/admin/addnotification",
  GetNotificationsList: "/admin/getnotification-history",
  getAppIssueList:"/admin/get-issue-list",
  //FAQ
  AddFAQs: "/admin/addfaq",
  FAQsList: "/admin/get-faq-list",
};
