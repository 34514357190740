import * as React from "react";
import { styled } from "@mui/system";
import { Tabs } from "@mui/base/Tabs";
import { TabsList as BaseTabsList } from "@mui/base/TabsList";
import { TabPanel as BaseTabPanel } from "@mui/base/TabPanel";
import { buttonClasses } from "@mui/base/Button";
import { Tab as BaseTab, tabClasses } from "@mui/base/Tab";
import PaymentDataGrid from "./PaymentDataGrid";
import { useTheme } from "@mui/material";

export default function PaymentHistoryTabs() {
  const rows = [
    { id: 1, Date: "Snow", Questions: "Jon", Amount: 141, Status: "Done" },
    { id: 2, Date: "Snow", Questions: "Jon", Amount: 142, Status: "pending" },
    { id: 3, Date: "Snow", Questions: "Jon", Amount: 143, Status: "Done" },
    { id: 4, Date: "Snow", Questions: "Jon", Amount: 144, Status: "pending" },
    { id: 5, Date: "Snow", Questions: "Jon", Amount: 145, Status: "Done" },
    { id: 6, Date: "Snow", Questions: "Jon", Amount: 146, Status: "pending" },
    { id: 7, Date: "Snow", Questions: "Jon", Amount: 147, Status: "Done" },
    { id: 8, Date: "Snow", Questions: "Jon", Amount: 148, Status: "rejcted" },
    { id: 9, Date: "Snow", Questions: "Jon", Amount: 149, Status: "Done" },
  ];
  const theme = useTheme();

  const Tab = styled(BaseTab)`
  font-family: "Helventica;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  width: 100%;
  line-height: 1.5;
  padding: 8px 12px;
  margin: 6px;
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  background-color: #fff;
  border:1px solid #DBDBDB75;

  &:hover {
    background-color: #DBDBDB;
  }

  &.${tabClasses.selected} {
    background-color: #fff;
    border:1px solid ${theme.palette.background.main};
    color: ${theme.palette.background.main};
  }

  &.${buttonClasses.disabled} {
    cursor: not-allowed;
  }
`;

  const tabList = [
    { id: 1, title: "All", screen: <PaymentDataGrid data={rows} /> },
    { id: 2, title: "Complete", screen: <PaymentDataGrid data={rows} /> },
    { id: 3, title: "Pending", screen: <PaymentDataGrid data={rows} /> },
    { id: 4, title: "Rejected", screen: <PaymentDataGrid data={rows} /> },
  ];
  return (
    <Tabs defaultValue={"All"}>
      <TabsList
        sx={{ width: "500px", display: "flex", justifyContent: "start" }}
      >
        {tabList.map((x) => {
          return (
            <Tab sx={{ width: "100%" }} value={x.title}>
              {x.title}
            </Tab>
          );
        })}
      </TabsList>
      {tabList.map((x) => {
        return (
          <TabPanel value={x.title}>
            {x.title}
            {x.screen}
          </TabPanel>
        );
      })}
    </Tabs>
  );
}

const TabPanel = styled(BaseTabPanel)`
  width: 100%;
  font-family: Helventica;
  font-size: 0.875rem;
  padding:1rem;
  border-radius:12px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08)
  };
`;
const TabsList = styled(BaseTabsList)`
  border-radius: 12px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: start;
  align-content: space-between;
`;
