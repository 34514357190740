import { PathList } from "../../Common/Routes/Path";
// import dashboard from "../../Assets/image/dash/sb/dash.svg";
import dashboardA from "../../Assets/image/dash/sb/dashA.svg";
// import Booking from "../../Assets/image/dash/sb/Booking.svg";
import BookingA from "../../Assets/image/dash/sb/BookingA.svg";
// import FAQ from "../../Assets/image/dash/sb/faq.svg";
import FAQActive from "../../Assets/image/dash/sb/faqA.svg";
// import Notificaton from "../../Assets/image/dash/sb/noti.svg";
import NotificatonA from "../../Assets/image/dash/sb/notiA.svg";
// import trainer from "../../Assets/image/dash/sb/Trainer.svg";
// import user from "../../Assets/image/dash/sb/user.svg";
import userA from "../../Assets/image/dash/sb/userA.svg";
import expertA from "../../Assets/image/dash/sb/expertA.svg";
// import pay from "../../Assets/image/dash/sb/pay.svg";
import payA from "../../Assets/image/dash/sb/payA.svg";
// import support from "../../Assets/image/dash/sb/support.svg";
import supportA from "../../Assets/image/dash/sb/supportA.png";
// import appissue from "../../Assets/image/dash/sb/appissue.svg";
import appissueA from "../../Assets/image/dash/sb/appissueA.svg";

export const NavMenuList = [
  {
    title: "Dashboard",
    // icon: dashboard,
    icon: dashboardA,
    path: PathList.Dashboard,
    active: dashboardA,
  },
  {
    title: "Booking Details",
    // icon: Booking,
    active: BookingA,
    icon: BookingA,
    path: PathList.Booking,
  },
  {
    title: "User Management",
    // icon: user,
    icon: expertA,
    path: PathList.UserManagement,
    active: expertA,
  },
  {
    title: "Trainer Management",
    // icon: trainer,
    icon: userA,
    path: PathList.Trainer,
    active: userA,
  },
  {
    title: "Notification",
    // icon: Notificaton,
    icon: NotificatonA,
    path: PathList.Notification,
    active: NotificatonA,
  },
  {
    title: "Payment History",
    // icon: pay,
    icon: payA,
    path: PathList.PaymentHistory,
    active: payA,
  },
  {
    title: "FAQ",
    // icon: FAQ,
    icon: FAQActive,
    path: PathList.FAQs,
    active: FAQActive,
  },
  {
    title: "Support",
    // icon: support,
    icon: supportA,
    path: PathList.Support,
    active: supportA,
  },
  {
    title: "Application Issue",
    // icon: appissue,
    icon: appissueA,
    path: PathList.AppIssue,
    active: appissueA,
  },
];
