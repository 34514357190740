import * as Yup from "yup"

export const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Invalid Email.").required("Required."),
    // name: Yup.string("Invalid Name").required("Required."),
    password: Yup.string()
        .required("Required.")
        .min(6, "Password must be at least 6 - 18 characters.").max(16, "Password must be at least 6 - 18 characters.")
})

export const ForgotPassword = Yup.object().shape({
    email: Yup.string().email("Invalid Email.").required("Required."),
})


export const FaqForm = Yup.object().shape({
    Question: Yup.string().required("Required"),
    Answer: Yup.string().required("Required")
})
