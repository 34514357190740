import { Box } from "@mui/material";
import React from "react";
import progressImg from "../Assets/image/progress.svg";

export default function WorkInProgress() {
  return (
    <Box
      sx={{
        width: "100%",
        height: "calc(100vh - 77px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={progressImg} alt="" />
    </Box>
  );
}
