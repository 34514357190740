import { Box, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AxiosInstance from "../../Common/Api/ApiHelper";
import { apiList } from "../../Common/Api/ApiList";
import { toast } from "react-toastify";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import StepperComponent from "./StepperComponent";
import Loader from "../../Common/Loader";
import { PathList } from "../../Common/Routes/Path";
import ErrorToaster from "../../Component/ErrorToaster";
import moment from "moment";

export default function BookingDetaileScreen() {
  const { id } = useParams();
  const theme = useTheme();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [address, setaddress] = useState();

  const GetAddressFromLng = async (lat, lng) => {
    try
    {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBxwKI7SmYgpPKeO2-vkOda_SDEdqgzYyE`
      );
      const data = await response?.json();
      if (data?.status === "OK")
      {
        return setaddress(data?.results[0]?.formatted_address);
      } else
      {
        throw new Error("Failed to fetch address");
      }
    } catch (error)
    {
      console.log(error.message);
      return null;
    }
  };

  useEffect(() => {
    if (data?.trainingDetails?.location.to_lat)
    {
      GetAddressFromLng(
        data?.trainingDetails?.location.to_lat,
        data?.trainingDetails?.location.to_lang
      );
    }
  }, [data]);
  const getPreferance = (data) => {
    if (data?.training_type === 1 && data?.training_preference === 1)
    {
      return "Virtual Instant Training";
    } else if (data?.training_type === 1 && data?.training_preference === 2)
    {
      return "Instant Onsite(At user location)";
    } else if (data?.training_type === 1 && data?.training_preference === 3)
    {
      return "Instant Onsite(At trainer location)";
    } else if (data?.training_type === 2 && data?.training_preference === 1)
    {
      return "Virtual Future Training";
    } else if (data?.training_type === 2 && data?.training_preference === 2)
    {
      return "Future Onsite (At user location)";
    } else if (data?.training_type === 2 && data?.training_preference === 3)
    {
      return "Future Onsite (At trainer location)";
    }
  };

  const userDetails = [
    { title: "Name", text: data?.userDetails?.name },
    { title: "Email", text: data?.userDetails?.email },
    {
      title: "Training Type",
      text:
        (data?.userDetails?.training_type === 1
          && "Instant training") ||
        (data?.userDetails?.training_type === 2 && "Virtual training")
    },
    {
      title: "Training Preferance",
      text: getPreferance(data?.userDetails),
    },

    { title: "Category", text: data?.userDetails?.category },
    { title: "Sub Category", text: data?.userDetails?.sub_category },
    {
      title: "Date",
      text: moment(data?.userDetails?.booking_date).format("DD/MM/YYYY"),
    },
    { title: "Time", text: `${moment(data?.userDetails?.from_time, 'hh:mm:ss').utc().format('hh:mm A')} - ${moment(data?.userDetails?.to_time, 'hh:mm:ss').utc().format('hh:mm A') }` },
  ];

  const TrainerDetails = [
    { title: "Trainer Name", text: data?.trainingDetails?.trainer_name },
    { title: "Rating", text: data?.trainingDetails?.rating_point },
    { title: "Bio", text: data?.trainingDetails?.bio },
    { title: "Session Rate", text: `$${data?.trainingDetails?.session_rate}` },
  ];

  data?.userDetails?.training_preference !== 1 &&
    TrainerDetails.push({ title: "Location", text: address });

  const fetchData = async () => {
    try
    {
      const res = await AxiosInstance.get(
        `${apiList.GetBookingDetailsById}?id=${id}`
      );
      if (res?.data?.s === 1 && res?.data?.r)
      {
        setData(res?.data?.r);
      } else
      {
        toast.error(res?.data?.m);
      }
      setIsLoading(false);
    } catch (error)
    {
      setIsLoading(false);
      <ErrorToaster error={error} />;
    }
  };

  useEffect(() => {
    fetchData(); //eslint-disable-next-line
  }, [id]);

  return (
    <Box
      sx={{ width: "100%", height: `calc(100vh - 77px)`, }}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ fontWeight: "600" }}>Details {`#${id}`}</Box>
            {/* <Box sx={{ display: "flex", gap: "1rem" }}>
              <Button
                variant="outlined"
                sx={{
                  background: theme.palette.primary.Blend,
                  color: "white",
                  textTransform: "none",
                  boxShadow: "3px 3px 7px 0 #00000040",
                  borderRadius: "6px",
                }}
              >
                <img src={downloadIcon} style={{ marginRight: "3px" }} alt="" />
                Download Invoice
              </Button>
              <Button
                variant="outlined"
                sx={{
                  height: "36px",
                  width: "30px",
                  padding: "0",
                  boxShadow: "3px 3px 7px 0 #00000040",
                  borderRadius: "6px",
                }}
              >
                <img
                  src={menuIcon}
                  style={{ height: "100%", width: "100%" }}
                  alt=""
                />
              </Button>
            </Box> */}
          </Box>
          <Box
            sx={{
              m: "1rem 0",
              boxShadow: "3px 3px 7px 0 #00000040",
              borderRadius: "5px",
            }}
          >
            <StepperComponent data={data && data?.order_process_status} />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { sm: "column", lg: "row" },
              justifyContent: "center",
              gap: "1rem",
              pb: "1rem",
            }}
          >
            <Box
              sx={{
                width: { sm: "100%", lg: "33%" },
                boxShadow: "3px 3px 7px 0 #00000040",
                overflow: "hidden",
                borderRadius: "9px",
                height: "fit-content",
              }}
            >
              <Box
                sx={{
                  background: theme.palette.primary.Blend,
                  width: "100%",
                  color: "white",
                  padding: "10px",
                  borderTopLeftRadius: "9px",
                  borderTopRightRadius: "9px",
                }}
              >
                User Details
              </Box>
              {userDetails && userDetails?.map((x, i) => {
                return (
                  <Box key={i} sx={{ display: "flex", width: "100%" }}>
                    <Box
                      sx={{
                        background: theme.palette.background.thead,
                        width: "30%",
                        padding: "10px",
                        borderBottom: "1px solid white",
                      }}
                    >
                      {x?.title}
                    </Box>
                    <Box
                      sx={{
                        width: "70%",
                        padding: "10px",
                        fontWeight: "600",
                        borderBottom: `1px solid ${theme.palette.background.thead}`,
                      }}
                    >
                      {x?.title === "Name" ? (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box>{x?.text}</Box>
                            <Box
                              sx={{
                                fontWeight: "400",
                                textDecoration: "underline",
                                color: "#3C75D4FA",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                navigate(
                                  `${PathList.UserDetails}/${data?.userDetails?.user_id}`
                                )
                              }
                            >
                              Details
                            </Box>
                          </Box>
                        </>
                      ) : (
                        x?.text
                      )}
                    </Box>
                  </Box>
                );
              })}
            </Box>
            <Box
              sx={{
                width: { sm: "100%", lg: "33%" },
                boxShadow: "3px 3px 7px 0 #00000040",
                overflow: "hidden",
                borderRadius: "9px",
                height: "fit-content",
              }}
            >
              <Box
                sx={{
                  background: theme.palette.primary.Blend,
                  width: "100%",
                  color: "white",
                  padding: "10px",
                  borderTopLeftRadius: "9px",
                  borderTopRightRadius: "9px",
                }}
              >
                Training Details
              </Box>
              {TrainerDetails && TrainerDetails?.map((x, i) => {
                return (
                  <Box key={i} sx={{ display: "flex", width: "100%" }}>
                    <Box
                      sx={{
                        background: theme.palette.background.thead,
                        width: "30%",
                        padding: "10px",
                        borderBottom: "1px solid white",
                      }}
                    >
                      {x?.title}
                    </Box>
                    <Box
                      sx={{
                        width: "70%",
                        padding: "10px",
                        fontWeight: "600",
                        borderBottom: `1px solid ${theme.palette.background.thead}`,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {x?.title === "Trainer Name" ? (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Box>{x?.text}</Box>
                            <Box
                              sx={{
                                fontWeight: "400",
                                textDecoration: "underline",
                                color: "#3C75D4FA",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                navigate(
                                  `${PathList.TrainerDetails}/${data?.trainingDetails?.trainer_id}`
                                )
                              }
                            >
                              Details
                            </Box>
                          </Box>
                        </>
                      ) : x?.title === "Location" ? (
                        <Typography
                          sx={{
                            cursor: "pointer",
                            fontWeight: "600",
                            "&:hover": {
                              textDecoration: "underline",
                              color: "#3f3fcf",
                            },
                          }}
                          onClick={() => {
                            window.open(
                              "https://www.google.com/maps/search/" + address
                            );
                          }}
                        >
                          {address}
                        </Typography>
                      ) : (
                        x?.text
                      )}
                      {x?.title === "Rating" && (
                        <StarRoundedIcon sx={{ color: "#F5CD00" }} />
                      )}
                    </Box>
                  </Box>
                );
              })}
            </Box>
            <Box
              sx={{
                width: { sm: "100%", lg: "33%" },
                boxShadow: "3px 3px 7px 0 #00000040",
                overflow: "hidden",
                borderRadius: "9px",
                height: "fit-content",
              }}
            >
              <Box
                sx={{
                  background: theme.palette.primary.Blend,
                  width: "100%",
                  color: "white",
                  padding: "10px",
                  borderTopLeftRadius: "9px",
                  borderTopRightRadius: "9px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box>Payment Details</Box>
                <Box>
                  {/*  */}
                  {data?.payment_details?.is_payment === 1 ? (
                    <>
                      <span style={{ color: "greenYellow", background: '#ffffff45', letterSpacing: '1px', fontWeight: "bold", fontSize: '1rem', padding: '5px 10px', borderRadius: '9px' }}>
                        PAID                  </span>
                    </>
                  ) : data?.payment_details?.is_payment === 0 ? (
                    <>
                      <span style={{ color: "#EB0000", background: '#ffffff45', letterSpacing: '1px', fontWeight: "bold", fontSize: '1rem', padding: '5px 10px', borderRadius: '9px' }}>
                        UNPAID
                      </span>
                    </>
                  ) : data?.payment_details?.is_payment === -1 && (<>
                          <span style={{ color: "greenYellow", background: '#ffffff45', letterSpacing: '1px', fontWeight: "bold", fontSize: '1rem', padding: '5px 10px', borderRadius: '9px' }}>
                      REFUND
                    </span>
                  </>)}
                </Box>
              </Box>
              <Box sx={{ display: "flex", width: "100%" }}>
                <Box
                  sx={{
                    background: theme.palette.background.thead,
                    width: "30%",
                    padding: "10px",
                    borderBottom: "1px solid white",
                  }}
                >
                  Total Amount
                </Box>
                <Box
                  sx={{
                    width: "70%",
                    padding: "10px",
                    borderBottom: `1px solid ${theme.palette.background.thead}`,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "600",
                  }}
                >
                  ${data?.payment_details?.sub_total}
                </Box>
              </Box>
              <Box sx={{ display: "flex", width: "100%" }}>
                <Box
                  sx={{
                    background: theme.palette.background.thead,
                    width: "30%",
                    padding: "10px",
                    borderBottom: "1px solid white",
                  }}
                >
                  Tax 20%
                </Box>
                <Box
                  sx={{
                    width: "70%",
                    padding: "10px",
                    borderBottom: `1px solid ${theme.palette.background.thead}`,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "600",
                  }}
                >
                  ${data?.payment_details?.system_fee}
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  borderTop: "1px solid #dcdcdc",
                }}
              >
                <Box
                  sx={{
                    background: theme.palette.background.thead,
                    width: "30%",
                    padding: "10px",
                    borderBottom: "1px solid white",
                  }}
                >
                  Final Amount
                </Box>
                <Box
                  sx={{
                    width: "70%",
                    padding: "10px",
                    borderBottom: `1px solid ${theme.palette.background.thead}`,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "600",
                  }}
                >
                  ${data?.payment_details?.total_amount}
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}
