import { Route, Routes } from "react-router-dom";
import { PathList } from "./Path";
import LoginScreen from "../../Screens/Login/LoginScreen";
import UserManagementScreen from "../../Screens/UserManagementScreen/UserManagementScreen";
import TrainerManagementScreen from "../../Screens/TrainerManagementScreen/TrainerManagementScreen";
import BookingDetaileScreen from "../../Screens/BookingDetailsScreen/BookingDetaileScreen";
import BookingScreen from "../../Screens/BookingScreen/BookingScreen";
import UserDetailsPage from "../../Screens/UserManagementScreen/UserDetailsPage";
import TrainerDetails from "../../Screens/TrainerManagementScreen/TrainerDetails";
import MainLayout from "../../Screens/Layout/Layout";
import WorkInProgress from "../../Screens/WorkInProgress";
import { Middleware } from "./Middleware";
import DashboardScreen from "../../Screens/Dashboard/DashboardScreen";
import PaymentHistoryScreen from "../../Screens/PaymentHistoryScreen/PaymentHistoryScreen";
import NotificationScreen from "../../Screens/NotificationScreen/NotificationScreen";
import FAQs from "../../Screens/FAQs/FAQs";
import AppIssue from "../../Screens/AppIssue/AppIssue";
import ChatScreen from "../../Screens/SupportScreen/Chat/ChatScreen";
import Supportscreen from "../../Screens/SupportScreen/Support/Supportscreen";

export default function RoutesList() {
  return (
    <Routes>
      <Route path={PathList.Login} element={<LoginScreen />} />
      <Route
        path={PathList.Dashboard}
        // element={<Middleware children={<MainLayout element={<DashboardScreen />} />} />}
        element={
          <Middleware children={<MainLayout element={<WorkInProgress />} />} />
        }
      />
      <Route
        path={PathList.Booking}
        element={<Middleware children={<MainLayout element={<BookingScreen />} />} />}
      // element={
      //   <Middleware children={<MainLayout element={<WorkInProgress />} />} />
      // }
      />
      <Route
        path={PathList.UserManagement}
        element={
          <Middleware
            children={<MainLayout element={<UserManagementScreen />} />}
          />
        }
      />
      <Route
        path={PathList.Trainer}
        element={
          <Middleware
            children={<MainLayout element={<TrainerManagementScreen />} />}
          />
        }
      />
      <Route
        path={PathList.Notification}
        // element={<Middleware children={<MainLayout element={<NotificationScreen />} />} />}
        element={
          <Middleware children={<MainLayout element={<WorkInProgress />} />} />
        }
      />
      <Route
        path={PathList.PaymentHistory}
        // element={<Middleware children={<MainLayout element={<PaymentHistoryScreen />} />}/>}
        element={
          <Middleware children={<MainLayout element={<WorkInProgress />} />} />
        }
      />
      <Route
        path={PathList.Support}
        // element={<Middleware children={<MainLayout element={<Supportscreen />} />} />}
        element={
          <Middleware children={<MainLayout element={<WorkInProgress />} />} />
        }
      />
      <Route
        path={`${PathList.Chat}/:id`}
        // element={<Middleware children={<MainLayout element={<ChatScreen />} />} />}
        element={
          <Middleware children={<MainLayout element={<WorkInProgress />} />} />
        }
      />
      <Route
        path={`${PathList.Booking_Details}/:id`}
        element={<Middleware children={<MainLayout element={<BookingDetaileScreen />} />} />}
      // element={
      // <Middleware children={<MainLayout element={<WorkInProgress />} />} />
      // }
      />
      <Route
        path={`${PathList.UserDetails}/:id`}
        element={
          <Middleware children={<MainLayout element={<UserDetailsPage />} />} />
        }
      />
      <Route
        path={`${PathList.TrainerDetails}/:id`}
        element={
          <Middleware children={<MainLayout element={<TrainerDetails />} />} />
        }
      />
      <Route
        path={PathList.FAQs}
        //  element={<Middleware children={<MainLayout element={<FAQs />} />} />}
        element={
          <Middleware children={<MainLayout element={<WorkInProgress />} />} />
        }
      />
      <Route
        path={PathList.AppIssue}
        // element={<Middleware children={<MainLayout element={<AppIssue />} />} />}
        element={
          <Middleware children={<MainLayout element={<WorkInProgress />} />} />
        }
      />
    </Routes>
  );
}
