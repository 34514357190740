import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import NoDataImg from "../../../Common/NoData";
import moment from "moment";
import SlotDrop from "./SlotDrop";
import AxiosInstance from "../../../Common/Api/ApiHelper";
import { apiList } from "../../../Common/Api/ApiList";
import { useParams } from "react-router-dom";
import Loader from "../../../Common/Loader";

export default function SlotDetails() {
  const [data, setData] = useState([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState(0);
  const [selectedYear, setSelectedYear] = useState(0);

  const getData = async (m, y) => {
    try
    {
      setLoading(true);
      let params = new URLSearchParams({
        id: id,
      });

      if (m !== 0)
      {
        params.append("month", m);
      }
      if (y !== 0)
      {
        params.append("year", y);
      }

      const response = await AxiosInstance.get(
        `${apiList.TrainersDetails_slots}/?${params.toString()}`
      );

      setData(response?.data?.s === 1 && response?.data?.r);
      setLoading(false);
    } catch (error)
    {
      console.log(error);
    }
  };

  const DateWiseGroup =
    data?.length > 0 &&
    data?.reduce((x, detail) => {
      const date = moment(detail?.date).format("DD MMM YYYY");
      if (!x[date]) x[date] = [];
      x[date].push(detail);
      return x;
    }, {});

  const sortedDates = Object.keys(DateWiseGroup).sort((a, b) =>
    moment(a, "DD MMM YYYY").diff(moment(b, "DD MMM YYYY"))
  );

  const SortedDateWiseGroup = sortedDates?.reduce((acc, date) => {
    acc[date] = DateWiseGroup[date];
    return acc;
  }, {});

  useEffect(() => {
    // if (selectedMonth === 0 && selectedYear === 0) {
    getData(selectedMonth, selectedYear);
    // } else if (selectedMonth !== 0 && selectedYear !== 0) {
    //   getData(selectedMonth, selectedYear);
    // }
  }, [selectedMonth, selectedYear]);

  return (
    <Box
      sx={{
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        width: "100%",
        maxHeight: { md: "calc(100vh - 350px)" },
        overflow: { md: "scroll" },
        // minHeight: '350px',
        borderRadius: "8px",
        position: "relative",
        // mb: "1rem",
      }}
      className='HideScrollBar'
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "100%",
          pr: "1rem",
          position: "sticky",
          top: "0",
          borderTopRightRadius: "11px",
          borderTopLeftRadius: "11px",
          background:
            "linear-gradient(181.45deg, #7AACFF -80.86%, rgba(60, 117, 213, 0.98) 98.77%)",
        }}
      >
        <Typography
          sx={{
            p: "1rem",
            height: "50px",
            // background:
            //   "linear-gradient(181.45deg, #7AACFF -80.86%, rgba(60, 117, 213, 0.98) 98.77%)",
            color: "white",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          }}
        >
          Slot Details
        </Typography>

        <SlotDrop
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
          setSelectedMonth={setSelectedMonth}
          setSelectedYear={setSelectedYear}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 2,
          height: "calc(100% - 50px)",
          overflowY: "scroll",
        }}
        className='HideScrollBar'
      >
        {loading ? (
          <Box sx={{ height: "350px" }}>
            <Loader />
          </Box>
        ) : data?.length > 0 ? (
          Object.keys(SortedDateWiseGroup).map((date) => (
            <>
              <Box
                key={date}
                sx={{
                  p: 2,
                  display: "flex",
                  gap: "1rem",
                  borderRadius: "12px",
                  alignItems: "center",
                  justifyContent: "start",
                  borderBottom: "1px solid #d5d5d5",
                }}
              >
                <Box sx={{ borderRight: "1px solid grey", width: "220px" }}>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "600",
                      // background: "#4949491c",
                      padding: "0.5rem",
                      borderRadius: "5px",
                      textAlign: "center",
                    }}
                  >
                    {date}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                    justifyContent: "start",
                    width: "100%",
                    overflowX: "scroll",
                  }}
                 
                >
                  {DateWiseGroup[date].map((detail, i) => (
                    <Box
                      key={i}
                      sx={{
                        background:
                          detail?.is_avail === 1 ? "#CEE5FF" : "#FACCCC",

                        borderRadius: "5px",
                        width: "200px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          width: "200px",
                          textAlign: "center",
                          padding: "10px",
                        }}
                      >
                        {moment(detail.frm_time, "hh:mm:ss").utc().format("hh:mm A")}{" "}
                        - {moment(detail.to_time, "hh:mm:ss").utc().format("hh:mm A")}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
              {/* <hr style={{ margin: "0", padding: '0' }} /> */}
            </>
          ))
        ) : (
          <Box sx={{ padding: "1rem", width: "100%", height: "100%" }}>
            <NoDataImg />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: !loading && data.length > 0 ? "flex" : "none",
          justifyContent: "end",
          alignItems: "center",
          padding: "0.5rem",
          fontWeight: "500",
          gap: "1rem",
          pr: "1rem",
          position: "sticky",
          bottom: "0",
          right: "0",
        }}
      >
        <Box sx={{ display: "flex", gap: "0.25rem", alignItems: "center" }}>
          <Box
            sx={{
              background: "#CEE5FF",
              height: "15px",
              width: "15px",
              border: "1px solid blue",
            }}
          ></Box>{" "}
          Available
        </Box>
        <Box sx={{ display: "flex", gap: "0.25rem", alignItems: "center" }}>
          <Box
            sx={{
              background: "#FACCCC",
              height: "15px",
              width: "15px",
              border: "1px solid red",
            }}
          ></Box>{" "}
          Booked
        </Box>
      </Box>
    </Box>
  );
}
