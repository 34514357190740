import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

const activeSteps = [
  "New order",
  "Confirmed",
  "On the way",
  "Arrived",
  "Start session",
  "Completed",
];

const cancelledSteps1 = [
  "New order",
  "Cancelled",

];
const cancelledSteps2 = [
  "New order",
  "Confirmed",
  "Cancelled",

];

export default function StepperComponent({ data }) {
  const [activeStep, setActiveStep] = React.useState();

  React.useEffect(() => {
    setActiveStep(data && data[data?.length - 1]?.session_status);
  }, [data]);

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        style={{ padding: "15px" }}
      >
        {(activeStep === 7
          ? data?.length === 2
            ? cancelledSteps1
            : cancelledSteps2
          : activeSteps
        ).map((label, index) => {
          return (
            <Step key={index}>
              <StepLabel
                sx={{
                  ".MuiStepLabel-labelContainer": {
                    color: "#aaaaaa",
                  },
                  "& .MuiSvgIcon-root": {
                    color:
                      activeStep === 7 && index === data?.length - 1
                        ? "#f44c4c"
                        : activeStep === 7 && index > 2
                          ? "grey"
                          : "null",
                  },
                }}
              >
                <span
                  style={{
                    color:
                      activeStep === 7 &&
                      index === data?.length - 1 &&
                      "#f44c4c",
                  }}
                >
                  {label}
                </span>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}

// import * as React from "react";
// import Box from "@mui/material/Box";
// import Stepper from "@mui/material/Stepper";
// import Step from "@mui/material/Step";
// import StepLabel from "@mui/material/StepLabel";
// import CancelIcon from "@mui/icons-material/Cancel";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import { Typography } from "@mui/material";

// const activeSteps = [
//   "New order",
//   "Confirmed",
//   "On the way",
//   "Arrived",
//   "Start session",
//   "Completed",
// ];

// const cancelledSteps1 = [
//   "New order",
//   "Cancelled",
//   "Confirmed",
//   "On the way",
//   "Arrived",
//   "Start session",
//   "Completed",
// ];

// const cancelledSteps2 = [
//   "New order",
//   "Confirmed",
//   "Cancelled",
//   "On the way",
//   "Arrived",
//   "Start session",
//   "Completed",
// ];

// export default function StepperComponent({ data }) {
//   const [activeStep, setActiveStep] = React.useState();

//   React.useEffect(() => {
//     setActiveStep(data && data[data?.length - 1]?.session_status);
//   }, [data]);

//   const isCancelled = activeStep === 7;
//   const cancelIndex = data?.length - 1;

//   const CustomStepIcon = (props) => {
//     const { icon, active, completed } = props;

//     return isCancelled ? (
//       icon > cancelIndex ? (
//         <CancelIcon style={{ color: '#f44c4c' }} />
//       ) : (
//         <CheckCircleIcon style={{ color: '#7AACFF', }} />
//       )
//     ) : completed ? (
//       <CheckCircleIcon style={{ color: '#7AACFF' }} />
//     ) : (
//       <span
//         style={{
//           borderRadius: "50%",
//           background: active ? '#7AACFF' : "grey",
//           margin: "auto",
//           height: "20px",
//           fontSize: "0.8rem",
//           color: "white",
//           width: "20px",
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         {icon}
//       </span>
//     );
//   };

//   return (
//     <Box sx={{ width: "100%" }}>
//       <Stepper
//         activeStep={activeStep}
//         alternativeLabel
//         style={{ padding: "15px" }}
//       >
//         {(activeStep === 7
//           ? data?.length === 2
//             ? cancelledSteps1
//             : cancelledSteps2
//           : activeSteps
//         ).map((label, index) => (
//           <Step key={index}>
//             <StepLabel
//               StepIconComponent={CustomStepIcon}
//               sx={{
//                 ".MuiStepLabel-labelContainer": {
//                   color:
//                     isCancelled && index >= cancelIndex ? "#f44c4c" : "#aaaaaa",
//                 },
//               }}
//             >
//               <Typography
//                 variant="body2"
//                 style={{
//                   color: isCancelled && index >= cancelIndex ? "#f44c4c" : null,
//                 }}
//               >
//                 {label}
//               </Typography>
//             </StepLabel>
//           </Step>
//         ))}
//       </Stepper>
//     </Box>
//   );
// }
