import {
  Box,
  Button,
  TablePagination,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import NoDataImg from "../../../Common/NoData";
import AxiosInstance from "../../../Common/Api/ApiHelper";
import { apiList } from "../../../Common/Api/ApiList";
import { DataGrid } from "@mui/x-data-grid";
import Loader from "../../../Common/Loader";
import { debounce } from "lodash";
// import { AppDomain } from "../../../Common/Constant";
// import userImg from "../../../Assets/image/user/9720009.jpg";/
import { useNavigate } from "react-router-dom";
import { PathList } from "../../../Common/Routes/Path";
import viewIcon from "../../../Assets/image/user/223_frame.png";
import moment from "moment";

const PAGE_SIZE_OPTIONS = [30];
export default function OrderDetails({ id }) {
  const [data, setdata] = useState([]);
  console.log(data);
  const [loading, setloading] = useState(true);
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [isLoading, setisLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
  const navigate = useNavigate();

  const status = [
    { id: 1, status: "Pending" },
    { id: 2, status: "Confirmed" },
    { id: 3, status: "On the way" },
    { id: 4, status: "Arrived" },
    { id: 5, status: "Start session" },
    { id: 6, status: "Completed" },
    { id: 7, status: "Cancelled" },
  ];

  const columns = [
    {
      headerClassName: "super-app-theme--header",
      field: "id",
      headerName: "ID",
      width: 80,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "userName",
      headerName: "User Name",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      valueGetter: (value, row) => {
        return `${row?.first_name || ""} ${row?.last_name || ""}`;
      },
    },

    {
      headerClassName: "super-app-theme--header",
      field: "booking_date",
      headerName: "Booking Date",
      minWidth: 250,
      flex: 1,
      headerAlign: "center",
      renderCell: (cell) => {
        return (
          <>
            <span>{`${moment(cell?.row?.booking_date).utc().format(
              "DD/MM/YYYY"
            )} - ${moment(cell?.row?.created_at).utc().format("hh:mm A")}`}</span>
          </>
        );
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "cat_name",
      headerName: "Category",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      renderCell: (x) =>
        x.row.cat_list.map((x, i) => <span key={i}>{x.sub_cat_name}</span>),
    },
    {
      headerClassName: "super-app-theme--header",
      field: "type",
      headerName: "Type",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      renderCell: (cell) => {
        if (cell?.row?.sub_type === 1)
        {
          return <span style={{ color: "#C37500" }}>Virtual</span>;
        } else
        {
          return <span style={{ color: "#1451B6" }}>Onsite</span>;
        }
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "total_amount",
      headerName: "Price",
      minWidth: 100,
      headerAlign: "center",
      renderCell: (cell) => `$${cell.row.total_amount}`,
    },
    {
      headerClassName: "super-app-theme--header",
      field: "is_payment",
      headerName: "Payment",
      type: "string",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      renderCell: (cell) => {
        return (
          <span style={{ color: cell.row.is_payment === 1 ? "green" : "red" }}>
            {cell.row.is_payment === 1 ? "Paid" : "Unpaid"}
          </span>
        );
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "session_status",
      headerName: "Order Status",
      type: "number",
      width: 180,
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <Button
            variant="contained"
            style={{
              background:
                cellValues?.row?.session_status === 1
                  ? theme.palette.button.pending
                  : cellValues?.row?.session_status === 2
                    ? theme.palette.button.confirm
                    : cellValues?.row?.session_status === 3
                      ? theme.palette.button.otw
                      : cellValues?.row?.session_status === 4
                        ? theme.palette.button.arrived
                        : cellValues?.row?.session_status === 5
                          ? theme.palette.button.session
                          : cellValues?.row?.session_status === 6
                            ? theme.palette.button.complete
                            : theme.palette.button.cancel,
              fontWeight: "500",
              textTransform: "none",
              width: "120px",
              color: "Black",
            }}
          >
            {status[cellValues?.row?.session_status - 1]?.status}
          </Button>
        );
      },
    },
    {
      headerClassName: "super-app-theme--header",
      headerName: "View",
      sortable: false,
      width: 150,
      headerAlign: "center",
      renderCell: (e) => (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={viewIcon}
            alt=""
            onClick={() =>
              navigate(`${PathList.Booking_Details}/${e?.row?.id}`)
            }
          />
        </Box>
      ),
    },
  ];

  const fetchData = async (page = 0, pageSize = PAGE_SIZE_OPTIONS[0]) => {
    let count = page * pageSize;
    try
    {
      setloading(true);
      setisLoading(true);
      const res = await AxiosInstance.get(
        `${apiList.TrainersDetails2}?t_id=${id}&type=3&count=${count}`
      );
      if (res?.data?.r?.data?.length > 0)
      {
        setdata(res?.data?.r?.data);

        setTotalData(res?.data?.r?.total_count);
      } else
      {
        setdata([]);
        setTotalData(0);
      }
      setloading(false);
      setisLoading(false);
    } catch (error)
    {
      setloading(false);
      console.log(error);
    }
  };

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Debounced fetch function
  const debouncedFetchUserData = useCallback(
    debounce((page, rowsPerPage) => fetchData(page, rowsPerPage), 300),
    []
  );

  useEffect(() => {
    debouncedFetchUserData();
  }, [debouncedFetchUserData, page, rowsPerPage]);

  return (
    <Box
      sx={{
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        width: "100%",
        height: { md: "calc(100vh - 330px)" },
        // minHeight: '400px',
        borderRadius: "8px",
      }}
    >
      <Typography
        sx={{
          p: "1rem",
          background:
            "linear-gradient(181.45deg, #7AACFF -80.86%, rgba(60, 117, 213, 0.98) 98.77%)",
          color: "white",
          height: "50px",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          position: "sticky",
          top: "0",
          display: loading || data?.length === 0 ? "block" : "none",
        }}
      >
        Session Details
      </Typography>
      <Box sx={{ height: "calc(100% - 50px)" }}>
        {loading ? (
          <>
            <Loader />
          </>
        ) : (
          <Box
            sx={{
              height: "100%",
            }}
          >
            {data && data?.length > 0 ? (
              <>
                <DataGrid
                  rows={data}
                  pagination
                  paginationMode="server"
                  disableColumnMenu={true}
                  // disableColumnSorting={true}
                  disableAutosize={true}
                  disableColumnFilter={true}
                  disableRowSelectionOnClick={true}
                  getRowId={(e) => e?.id}
                  hideFooter
                  loading={isLoading}
                  slots={{ noRowsOverlay: NoDataImg }}
                  columns={columns}
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    ".MuiDataGrid-cell": {
                      textAlign: "center",
                      "&:focus": { outline: "none" },
                    },
                    ".MuiDataGrid-cell:focus": { outline: "none" },
                    ".MuiDataGrid-columnHeaderTitle": {
                      fontWeight: "600",
                      color: "white",
                    },
                    "& .super-app-theme--header": {
                      background: theme.palette.primary.Blend,
                    },
                  }}
                  pageSize={rowsPerPage}
                />
                <TablePagination
                  component="div"
                  count={totalData}
                  page={page}
                  onPageChange={handlePageChange}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  rowsPerPageOptions={PAGE_SIZE_OPTIONS}
                />
              </>
            ) : (
              <>
                <NoDataImg />
              </>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}
